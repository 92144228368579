import React from "react"

export default function Terms() {
  return (
    <div className="box">
      <div className="content">
        <p>1. 性质</p>
        <p>睡吧是非盈利性质的社区，目前所有的志愿者都是在业余时间对失眠者提供帮助。提供咨询并不是谋取利润，而是给部分无法通过正常途径得到帮助的失眠者一个可选择的方式。</p>
        <p>2. 方式</p>
        <p>失眠者通过微信的语音通话进行咨询，这是最有效的方式。除了总结性的留言和反馈，不应该在咨询之外的其他时间进行文字形式的咨询。如果有太多需要提问和反馈，应该预约下一次个人咨询。</p>
        <p>3. 过程</p>
        <p>睡吧的个人咨询会以当前用户的评估为依据，提出相应的意见，并回答失眠者的疑问，试图解决造成失眠最根本的原因。失眠者得到的意见是生活中言行上的指导，而并非心理咨询，并不包含任何医学相关内容或者药物，也不会包含任何广告和刺激购买的行为。</p>
        <p>4. 求助对象</p>
        <p>提供咨询的人是睡吧组长和常年在论坛帮助他人的志愿者。大家通过自己走出失眠的经历和常年帮助失眠者的经验来提供帮助。</p>
        <p>5. 收费</p>
        <p>收取一定费用是对咨询者时间的补偿。咨询者收取的费用应该按照自己当前工作的时间价值计算。按照双方意愿，费用的支付可以发生在咨询之前或之后。如果出现不负责任的咨询，失眠者可以选择不支付费用。</p>
        <p>6. 效果</p>
        <p>并不是所有的咨询都能产生好的效果。如果失眠者认为咨询对自己没有帮助，可以停止继续咨询。但是要自行承担在这个过程中产生的费用。</p>
        <p>7. 言语</p>
        <p>在咨询过程中，双方不应该出现任何攻击性或者侮辱性的言辞。如果出现，任何一方可以停止对话。</p>
        <p></p>
      </div>
    </div>
  )
}