import React, { useState, useEffect } from 'react';
import EvaluationForm from './form'
import { navigate } from "@reach/router"
import { getLangText } from '../utils/common'

import {
  fetchAnswers,
  saveAnswers
} from './services'

export default function Evaluation(props) {
  const { sections } = props
  const [answers, setAnswers] = useState({})
  const [currentIndex, setCurrentIndex] = useState(0)
  const [error, setError] = useState(null)

  const onFormChange = val => {
    setAnswers(Object.assign({}, answers, val))
  }

  const onNextStep = () => {
    setCurrentIndex(sections.length > (currentIndex + 1) ? currentIndex + 1 : currentIndex)
  }

  const onPreviousStep = () => {
    setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : currentIndex)
  }

  const onSubmit = async () => {
    try {
      await saveAnswers(answers)

      navigate('/evaluate/result')
    } catch (err) {
      setError(err)
    }
  }

  const getQuestions = (sectionData) => {
    return sectionData.reduce((result, section) => result.concat(section.questions), [])
  }

  const currentQuestions = () => {
    const questions = sections.length > currentIndex ?
      sections[currentIndex].questions : []

    return questions.filter(q => {
      if (q.depends == null) return true

      const questionName = q.depends.question

      return answers[questionName] === q.depends.value
    })
  }

  const isCurrentSectionAccomplished = () => {
    return currentQuestions().every(item => answers[item.name] !== undefined)
  }

  const isLastStep = () => {
    return currentIndex === sections.length - 1
  }

  const isFirstStep = () => {
    return currentIndex === 0
  }

  // fetch evaluation and answers
  async function fetchData() {
    const answerData = await fetchAnswers(getQuestions(sections))

    setAnswers(answerData && answerData.results ? answerData.results : {})
  }

  useEffect(() => {
    fetchData();
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps

  const section = sections[currentIndex];
  const questions = currentQuestions()

  const createNavButtons = () => {
    const isAccomplished = isCurrentSectionAccomplished(answers)
    const nextText = isLastStep() ? '提交' : '下一页'

    return (
      <div className="level is-mobile m-t-lg">
        <div className="level-left">
          <button
            type="button"
            className={isFirstStep() ? "button level-item is-hidden" : "button level-item"}
            onClick={onPreviousStep}
            disabled={isFirstStep()}>
            上一页
          </button>
        </div>
        <div className="level-right">
          <button
            type="submit"
            className="button level-item"
            disabled={!isAccomplished}
            onClick={e => isLastStep() ? onSubmit(e) : onNextStep()}>
            {nextText}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="columns">
      <div className="column is-one-fifth">
      </div>
      <div className="column">
        <h1 className="title">睡眠评估 - {getLangText(section.description)}</h1>
        <div>
          <EvaluationForm
            questions={questions || []}
            answers={answers || {}}
            error={error}
            onChange={onFormChange}
          />
        </div>
        {createNavButtons()}
      </div>
      <div className="column is-one-fifth">
      </div>
    </div>
  )
}
