import React, { useState } from "react"
import { Link } from "gatsby"
import { navigate } from "@reach/router"
import axios from 'axios'
import * as queryString from 'query-string';
import * as classNames from 'classnames';

export default function ResetPassword(props) {
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const submitButtonCls = classNames('button', 'is-primary', { 'is-loading': loading });
  const queryParams = queryString.parse(props.location.search);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    
    axios.post(`${process.env.API_URL}/auth/reset-password`, {
      code: queryParams.code,
      password: password,
      passwordConfirmation: password
    })
      .then(res => {
        setLoading(false);
        navigate('/evaluate/login')
      })
      .catch(err => {
        setLoading(false);
        setError(err);
      });
  };

  const isValid = () => {
    return password && repeatPassword && (password === repeatPassword);
  };

  const renderContent = () => {
    return (
      <div className="box">
        <label className="label" htmlFor="password">新密码</label>
        <p className="control has-icon has-icon-right m-b-sm">
          <input
            className="input"
            aria-label="密码"
            name="password"
            type="password"
            onChange={e => setPassword(e.target.value)}
          />
        </p>
        <label className="label" htmlFor="repeat-password">确认新密码</label>
        <p className="control has-icon has-icon-right m-b-sm">
          <input
            className="input"
            aria-label="确认密码"
            name="repeat-password"
            type="password"
            onChange={e => setRepeatPassword(e.target.value)}
          />
        </p>
        <p className="control m-t-md">
          <button className={submitButtonCls} type="submit" disabled={!isValid()}>更改密码</button>
        </p>
        {error ? (
          <p className="help is-danger">服务器错误，请<Link href="mailto:hello.sleep.site@gmail.com">联系管理员</Link>：{error.message}</p>
        ) : null}
      </div>
    );
  };

  return (
    <form
      className="field"
      onSubmit={e => handleSubmit(e)}
    >
      <section className="hero is-bold">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column is-6 is-offset-3">
                {renderContent()}
                <p className="has-text-centered">
                  <Link to="/evaluate/login">继续登录</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>

  )
}