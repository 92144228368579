import React from "react"
import flatpickr from "flatpickr";
import { Mandarin } from "flatpickr/dist/l10n/zh.js"
import "flatpickr/dist/flatpickr.css"

class DatePicker extends React.Component {
  constructor() {
    super()

    this.onSelect = this.onSelect.bind(this)
    this.elem = React.createRef()
  }

  componentDidMount() {
    const {value, options} = this.props
    const elem = this.elem.current
    const {type, defaultDate, minDate, maxDate} = options
    const useTime = type === 'time'

    this.picker = flatpickr(elem, {
      dateFormat: useTime ? 'H:i' : 'Y-m-d',
      enableTime: useTime,
      noCalendar: useTime || false,
      time_24hr: true,
      defaultDate: value || defaultDate,
      minDate: minDate,
      maxDate: maxDate,
      locale: Mandarin,
      onChange: this.onSelect
    })

    if (value) {
      this.picker.setDate(value, false)
    }
  }

  componentDidUpdate() {
    const {value} = this.props

    if (value) {
      this.picker.setDate(value, false)
    }
  }

  onSelect(aDate) {
    const {name} = this.props
    this.props.onSelect({name, value: new Date(aDate)})
  }

  componentWillUnmount() {
    this.picker.destroy()
  }

  render() {
    const {name, error} = this.props;
    const inputClass = error ? `input is-danger` : `input`

    return (
      <div className="control">
        <input className={inputClass} type="text" aria-label="Date Picker" name={name} ref={this.elem} />
      </div>
    )
  }
}

export default DatePicker
