import { http, getLangText } from '../utils/common'
import { getCachedUser } from "../utils/auth"
import { getHost } from '../utils/methods'

export async function fetchAnswers(questions) {
  const user = getCachedUser();
  if (!user) return;

  const res = await http().get('/answers');
  const data = res.data
  const answer = data.length > 0 ? data[0] : {};

  if (!questions || !user) return answer;

  if (!answer.results) answer.results = {};

  // pre fill the results map
  questions.forEach(item => {
    if (!answer.results[item.name] && 
      (item.options.defaultValue !== null) && 
      (item.options.defaultValue !== undefined)) {
      answer.results[item.name] = item.options.defaultValue
    }

    if (item.name === 'name') answer.results['name'] = user.username;
    if (item.name === 'email') answer.results['email'] = user.email;
  })

  return answer;
}

export async function fetchAnswersWithId(id) {
  const res = await http().get(`/answers/${id}`);
  const answers = res.data

  return answers;
}

export async function saveAnswers(answers) {
  await http().post('/answers', {
    results: answers
  })
}

export async function applyConsultation({ email }) {
  const user = getCachedUser();
  if (!user) return;

  await http().post('/consultations', { email });
}

export function parseAnswers(answers, sections, answerId) {
  const questions = sections.reduce((result, section) => result.concat(section.questions), [])

  const getAnswerDescription = (question, answer) => {
    if (question.type === 'multiple') {
      // find the answer in an array
      const v = question.options.valueList.find(o => o.name === answer);

      return getLangText(v ? v.description : null)
    }

    if (question.type === 'range') {
      return `${answer}${getLangText(question.options.unitDescripton)}`
    }

    if (question.type === 'date') {
      const aDate = new Date(answer)
      const month = aDate.getUTCMonth() + 1
      var day = aDate.getUTCDate()
      var year = aDate.getUTCFullYear()

      return `${year}-${month}-${day}`
    }

    return answer
  }

  const getQuestion = (name) => {
    return questions.find(q => q.name === name)
  }

  const results = answers || {};
  const formatedAnswers = Object.keys(results)
    .filter(key => {
      return ['email'].indexOf(key) === -1 && getQuestion(key)
    })
    .map(key => {
      const answer = results[key]
      const question = getQuestion(key)
      const qDesc = getLangText(question.description)
      const aDesc = getAnswerDescription(question, answer)

      return { q: qDesc, a: aDesc }
    })

  const url = {
    q: '评估意见',
    a: `${getHost()}/evaluate/results/${answerId}`
  };

  return [url].concat(formatedAnswers);
}
