import React from "react"
import Field from './field'

class Question extends React.Component {
  render() {
    const { question, error, value } = this.props;

    return (
      <Field
        name={question.name}
        question={question}
        value={value}
        error={error}
        onChange={this.props.handleChanage}
      />
    );
  }
}

export default Question
