import React from 'react';
import { clearCachedUser } from '../utils/auth';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // handle authentication error
    if (error.response && error.response.status === 401) {
      clearCachedUser();
    }
    
    this.setState({
      error, errorInfo
    });
  }

  render() {
    if (this.state.error) {
      console.error(this.state.error);
    }

    return this.props.children;
  }
}