import React from "react"
import { navigate } from "@reach/router"
import Helmet from "react-helmet"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { FaCopy, FaArrowLeft, FaUserFriends } from "react-icons/fa"
import Clipboard from 'clipboard'
import { fetchAnswers } from "./services"
import AnswerTable from "./answer-table"

class EvaluateHelp extends React.Component {
  constructor() {
    super()

    this.gotoResult = this.gotoResult.bind(this)
    this.copyAnswers = this.copyAnswers.bind(this)

    this.tableElem = React.createRef()
    this.state = { answer: {} }
  }

  async componentDidMount() {
    this.clipboard = new Clipboard('.is-copy-button', {
      target: () => this.tableElem.current
    })

    const answer = await fetchAnswers();

    this.setState({ answer })
  }

  componentWillUnmount() {
    this.clipboard.destroy()
  }

  gotoResult() {
    navigate('/evaluate/result')
  }

  copyAnswers() {

  }

  renderNav() {
    return (
      <div className="field is-grouped">
        <div className="control">
          <button className="button is-primary is-copy-button" onClick={this.copyAnswers}>
            <FaCopy style={{ verticalAlign: `middle`, marginRight: 5 }} />
            复制评估
          </button>
        </div>
        <div className="control">
          <OutboundLink className="button is-light" target="_blank" href="http://www.douban.com/group/hellosleep/">
            <FaUserFriends style={{ verticalAlign: `middle`, marginRight: 5 }} />
            去睡吧小组
          </OutboundLink>
        </div>
        <div className="control">
          <button className="button is-light" onClick={this.gotoResult}>
            <FaArrowLeft style={{ verticalAlign: `middle`, marginRight: 5 }} />
            返回评估结果
          </button>
        </div>
      </div>
    )
  }

  render() {
    const { sections } = this.props;
    const { answer } = this.state;

    return (
      <div>
        <Helmet>
          <title>社区求助</title>
        </Helmet>
        <h1 className="title is-spaced">在社区求助</h1>
        <p className="subtitle">请复制下面的评估答案，并把它们发布到睡吧小组。</p>
        {this.renderNav()}
        <AnswerTable sections={sections} answer={answer.results} answerId={answer.id} ref={this.tableElem} />
      </div>
    )
  }
}

export default EvaluateHelp
