import React, { useState } from "react"
import { Link } from "gatsby"
import { validateEmail } from "../utils/methods"
import axios from 'axios'
import * as classNames from 'classnames';

export default function ForgetPassword() {
  const [email, setEmail] = useState('');
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const submitButtonCls = classNames('button', 'is-primary', { 'is-loading': loading });

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    
    axios.post(`${process.env.API_URL}/auth/forgot-password`, { email })
      .then(res => {
        setComplete(true);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        setError(err);
      });
  };

  const renderComplete = () => {
    // TODO: need have a timer for submit again to prevent attack
    return (
      <div className="box">
        <p className="m-b-md">重置密码的邮件已经发送到</p>
        <p className="m-b-md is-size-7">{email}</p>
        <p className="control m-t-md">
          <button className={submitButtonCls} type="submit">再次发送</button>
        </p>
      </div>
    )
  };

  const renderInput = () => {
    return (
      <div className="box">
        <label className="label" htmlFor="username">输入你的邮箱</label>
        <p className="control has-icon has-icon-right m-b-sm">
          <input
            className="input"
            aria-label="邮箱"
            name="username"
            type="email"
            placeholder="username@email.example.com"
            onChange={e => setEmail(e.target.value)}
          />
          {email && !validateEmail(email) ? (<p className="help is-danger">请输入正确的邮箱格式</p>) : null}
        </p>
        <p className="control m-t-md">
          <button className={submitButtonCls} type="submit" disabled={!validateEmail(email)}>发送邮件</button>
        </p>
        {error ? (
          <p className="help is-danger">服务器错误，请<Link href="mailto:hello.sleep.site@gmail.com">联系管理员</Link>：{error.message}</p>
        ) : null}
      </div>
    );
  };

  const renderContent = () => {
    if (complete) return renderComplete();

    return renderInput();
  }

  return (
    <form
      className="field"
      onSubmit={e => handleSubmit(e)}
    >
      <section className="hero is-bold">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column is-6 is-offset-3">
                {renderContent()}
                <p className="has-text-centered">
                  <Link to="/evaluate/login">继续登录</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>

  )
}