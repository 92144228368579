import React from "react"
import { navigate } from "@reach/router"
import Helmet from "react-helmet"
import { FaShareAlt, FaHeadphonesAlt, FaRedoAlt, FaList, FaFilePdf } from "react-icons/fa"
import markdown from '../utils/markdown'
import { HTMLContent } from '../components/content'
import { fetchAnswersWithId, fetchAnswers } from "./services"
import { getFacts } from "./analyser"
import { getLangText, getLangRichText } from "../utils/common"
import { getCachedUser } from "../utils/auth"
import { Link } from "gatsby"
import { exportResultAsPdf } from './pdf'
class EvaluateResult extends React.Component {
  constructor() {
    super()

    this.askHelp = this.askHelp.bind(this)
    this.reEvaluate = this.reEvaluate.bind(this)
    this.renderResult = this.renderResult.bind(this)
    this.state = {}
  }

  askHelp() {
    const { answers } = this.state

    navigate('/evaluate/help', { newAnswers: answers })
  }

  reEvaluate() {
    navigate('/evaluate/new')
  }

  getBookletContent(tagName) {
    const { booklets } = this.props;
    const booklet = booklets.find(b => b.tag === tagName)

    if (booklet) {
      const html = markdown(getLangRichText(booklet.content))

      return html
    }

    return ''
  }

  async componentDidMount() {
    try {
      const id = this.props.answerId
      const answers = id ? await fetchAnswersWithId(id) : await fetchAnswers();

      if (!answers) return;

      this.setState({ answers: answers.results || {} });
    } catch (e) {
      this.setState(() => {
        throw e;
      });
    }
  }

  renderResult(isFull = false) {
    const { answers } = this.state;
    const { instructioners } = this.props;

    if (!answers) return (<div></div>);

    if (answers && !Object.keys(answers).length) {
      navigate('/evaluate/new')

      return
    }

    const tags = getFacts(instructioners, answers);

    if (!tags.length) {
      return (<p>你的睡眠没有任何问题</p>)
    }

    return (
      <div>
        <p className="block">在阅读下面的意见之前，需要理解睡眠的好坏以及失眠所带来的负面想法和情绪只是现象，我们需要改善造成这些现象的原因。下面的所有意见都是针对造成失眠的原因，你只有遵循并坚持这些意见才能逐渐走出失眠的困境。</p>
        <div className="columns is-multiline">
          {tags.map((tag, index) => (
            <div key={`tag_${index}`} className={`column ${isFull ? 'is-full' : 'is-half'}`}>
              <article className="box">
                <p className="title">{getLangText(tag.description)}</p>
                <HTMLContent content={this.getBookletContent(tag.name)} />
              </article>
            </div>
          ))}
        </div>
      </div>
    )
  }

  renderNav() {
    const { answers } = this.state;
    const { answerId } = this.props;
    const user = getCachedUser();

    if (!answers) return null;

    if (answers && (!user || user.email !== answers.email)) {
      const toUrl = answerId ? `/evaluate/details/${answerId}` : '/evaluate/details';
      return (
        <div className="field is-grouped">
          <div className="control">
            <Link
              className="button is-light"
              to={toUrl}
            >
              <FaList style={{ verticalAlign: `middle`, marginRight: 5 }} />
              查看评估详情
            </Link>
          </div>
        </div >
      );
    }

    return (
      <div>
        <div className="columns is-multiline field is-grouped ">
          <div className="column is-narrow control">
            <button className="button is-primary" onClick={this.askHelp}>
              <FaShareAlt style={{ verticalAlign: `middle`, marginRight: 5 }} />
              分享到小组求助
            </button>
          </div>
          <div className="column is-narrow control">
            <Link
              className="button is-light"
              to="/evaluate/consult"
            >
              <FaHeadphonesAlt style={{ verticalAlign: `middle`, marginRight: 5 }} />
              个人咨询
            </Link>
          </div>
          <div className="column is-narrow control">
            <button
              className="button is-light"
              onClick={() => {
                this.setState({ isExportingPdf: true })
                exportResultAsPdf(this.renderResult(true), () => {
                  this.setState({ isExportingPdf: false })
                })
              }}
            >
              <FaFilePdf style={{ verticalAlign: `middle`, marginRight: 5 }} />
            导出PDF
            </button>
          </div>
          <div className="column is-narrow control">
            <button className="button is-light" onClick={this.reEvaluate}>
              <FaRedoAlt style={{ verticalAlign: `middle`, marginRight: 5 }} />
              重新评估
            </button>
          </div>
        </div>
        <div className={`modal ${this.state.isExportingPdf ? 'is-active' : ''}`}>
          <div className="modal-background"></div>
          <div className="modal-content">
            <div className="box">
              <p>正在生成PDF文件...</p>
              <progress class="progress is-medium is-dark" max="100"></progress>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>评估结果和意见</title>
        </Helmet>
        <h1 className="title is-spaced">评估结果和意见</h1>
        {this.renderNav()}
        {this.renderResult()}
      </div>
    )
  }
}

export default EvaluateResult
