import React, { useState } from "react"
import Helmet from "react-helmet"
import { FaArrowLeft } from "react-icons/fa"
import { Link, navigate } from "gatsby"
import Terms from "../components/terms"
import { getCachedUser } from "../utils/auth"
import { validateEmail } from "../utils/methods"
import { applyConsultation } from "./services"
import * as classNames from 'classnames';

export default function EvaluateConsult(props) {
  const user = getCachedUser();
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState(user.email);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const successModalCls = classNames('modal', { 'is-active': submitSuccess });
  const failedModalCls = classNames('modal', { 'is-active': submitError !== null });

  if (!user) {
    return (<div>请先登录</div>);
  }

  const renderNav = () => {
    return (
      <div className="field is-grouped">
        <div className="control">
          <Link className="button is-light" to="/evaluate/result">
            <FaArrowLeft style={{ verticalAlign: `middle`, marginRight: 5 }} />
            返回评估结果
          </Link>
        </div>
      </div>
    )
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    applyConsultation({ email })
      .then(() => setSubmitSuccess(true))
      .catch(err => setSubmitError(err));
  };

  return (
    <div>
      <Helmet>
        <title>个人咨询</title>
      </Helmet>
      <h1 className="title is-spaced">个人咨询</h1>
      {renderNav()}
      <p className="m-b-md">睡吧提供多种途径求助失眠问题，<span className="has-text-weight-semibold">只有在无法通过其他途径得到帮助的情况下才应该考虑个人咨询</span>。推荐的求助途径有：</p>
      <ol className="m-b-md">
        <li>1. 阅读并执行评估之后的<Link to="/evaluate/result">相关意见</Link>。</li>
        <li>2. 将评估结果<Link to="/evaluate/help">发布到睡吧小组</Link>，进行社区求助。</li>
      </ol>
      <p>确认邮件地址，同意下面的声明并点击求助，你会收到一封包含联系方式和收费标准的邮件。</p>
      <div className="columns">
        <div className="column is-three-quarters">
          <form className="m-t-md" onSubmit={e => handleSubmit(e)}>
            <label className="label" htmlFor="email">邮箱地址</label>
            <p className="control has-icon has-icon-right m-b-sm">
              <input
                className="input"
                aria-label="邮箱"
                name="email"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              {email && validateEmail(email) ? null : (<p className="help is-danger">请填写正确的邮箱地址</p>)}
            </p>
            <p className="has-text-weight-semibold m-t-md m-b-sm">声明</p>
            {Terms()}
            <div className="field">
              <div className="control">
                <label className="checkbox">
                  <input
                    className="m-r-sm"
                    aria-label="同意声明"
                    type="checkbox"
                    defaultChecked={checked}
                    onChange={() => setChecked(!checked)}
                  />
                  我同意以上声明。
              </label>
              </div>
            </div>
            <div className="field is-grouped">
              <div className="control">
                <button className="button is-primary" type="submit" disabled={!checked || !validateEmail(email)}>求助</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="column"></div>
      <div className="column"></div>
      <div className={successModalCls}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="box">
            提交咨询请求成功，请查收你的邮箱。关闭对话框回到评估意见页面。
          </div>
        </div>
        <button className="modal-close is-large" aria-label="关闭" onClick={() => {
          setSubmitSuccess(false);
          
          navigate('/evaluate/result');
        }}>
        </button>
      </div>
      <div className={failedModalCls}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="box">
            提交咨询请求失败。错误信息：{submitError && submitError.message}。请给<a href="mailto:hello.sleep.site@gmail.com">睡吧管理员发送邮件</a>
          </div>
        </div>
        <button className="modal-close is-large" aria-label="关闭" onClick={() => setSubmitError(null)}>
        </button>
      </div>

    </div>
  )
}
