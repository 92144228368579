
const affectThreshold = 3;
const idleThreshold = 5;
const unhealthThreshold = 4;
const hoursThreshold = 0.7;

const pointsMap = {
  "best": 5,
  "normal": 3,
  "little": 1,
  "none": 0
};

const boolMap = { "yes": true, "no": false };

function isAffected(args) {
  let count = 0;

  args.forEach(arg => {
    if (arg === 'yes') count++
  });

  return count >= affectThreshold;
}

function isIdle(args) {
  const pressure = args[0];
  const lively = args[1];

  if (!pressure || !lively) return false;

  const totalPoints = pointsMap[pressure] + pointsMap[lively];

  return totalPoints < idleThreshold;
}

function isHealthy(args) {
  const sport = args[0];
  const sunshine = args[1];
  const totalPoints = pointsMap[sport] + pointsMap[sunshine];

  return totalPoints < unhealthThreshold;
}

function isSleepEfficiency(args) {
  const hourstosleep = args[0];
  const hourstofallinsleep = args[1];

  const hours = parseFloat(hourstosleep);

  return parseFloat(hourstofallinsleep) / hours >= hoursThreshold;
}

function isStimuli(args) {
  const bedroom = args[0];
  const bed = args[1];

  return bedroom === 'yes' || bed === 'yes';
}

function getMethod(methodName) {
  if (methodName === "isAffected") return isAffected;
  if (methodName === "isIdle") return isIdle;
  if (methodName === "isHealthy") return isHealthy;
  if (methodName === "isSleepEfficiency") return isSleepEfficiency;
  if (methodName === "isStimuli") return isStimuli;

  throw new Error('Method name not found : ', methodName)
}

function isInstructionValid(instructioner, results) {
  const { type, answers, args } = instructioner
  // At the moment we only have one answer for each instructioner
  const { question, value } = answers[0];

  if (type === 'simple') {
    return results[question] === value
  }

  if (type === 'func') {
    const method = getMethod(question)
    const inputArgs = args.map(arg => results[arg].toString())

    return method(inputArgs) === boolMap[value]
  }
}

export function getFacts(instructioners, results) {
  return instructioners
    .filter(instructioner => isInstructionValid(instructioner, results))
    .map(instructioner => instructioner.fact)
}