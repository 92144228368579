import React from "react"
import ReactDOMServer from 'react-dom/server'
import SourceHanSansSCFont from '../../static/SourceHanSansSC-Regular-7000.ttf'
import { jsPDF } from "jspdf"
import axios from 'axios'
import Follow from '../components/follow'
import markdown from '../utils/markdown'

const wrapHtmlContent = (result, tutorials, docWidth) => {
  return `
    <html>
      <header>
        <style type="text/css">
          .pdf-container {
            font-family: sourceHanSansSCFont;
            font-size: 8px;
            width: ${docWidth - 36}px;
          }

          .pdf-container .title {
            font-size: 12px;
            font-weight: bold;
            padding: 0px;
            margin: 0px;
          }

          .pdf-container article {
            padding: 0px;
          }

          .pdf-container h1 {
            font-size: 12px;
          }

          .pdf-container h2 {
            font-size: 8px;
            font-weight: bold;
          }

          .tutorial, .tutorials {
            padding-top: 12px;
            padding-bottom: 12px;
          }
        </style>
      </header>
      <body>
        <div class="pdf-container">
          <h1>睡吧评估结果和意见</h1>
          <div class="result">${result}</div>
          <div class="tutorials">
            <h1>评估结果相关文章</h1>
            ${tutorials}
          </div>
          ${ReactDOMServer.renderToString(<Follow />)}
        </div>
      </body>
    </html>
  `
}

const initPdfDoc = async () => {
  const doc = new jsPDF({ format: 'letter', unit: 'px', filters: ["ASCIIHexEncode"], compress: true })

  const fontFile = await axios.get(SourceHanSansSCFont, {
    responseType: 'arraybuffer'
  });
  const fontData = Buffer.from(fontFile.data, 'binary').toString('base64')

  doc.addFileToVFS("SourceHanSansSC-Regular.ttf", fontData)
  doc.addFont("SourceHanSansSC-Regular.ttf", "sourceHanSansSCFont", "normal");

  return doc
}

const extractTutorialLinks = (html) => {
  const dom = new DOMParser().parseFromString(html, 'text/html')
  return [...dom.links]
    .map(l => l.pathname)
    .filter(l => l.startsWith('/tutorial'))
    .map(l => l.replace('/tutorial/', ''))
    .map(id => `http://hellosleep.net:1337/articles/${id}`)
}

export const exportResultAsPdf = async (resultComponent, callback) => {
  const doc = await initPdfDoc()
  const resultHtml = ReactDOMServer.renderToString(resultComponent)

  axios.all(
    extractTutorialLinks(resultHtml).map(l => axios.get(l))
  ).then(axios.spread((...responses) => {
    const tutorialsHtml = responses.map(res => res.data)
      .map(t => `<h1 class="tutorial">${t.title}</h1>${markdown(t.body)}`)
      .join('')
      .replace(/<img[^>]*>/g,'')

    doc.html(wrapHtmlContent(resultHtml, tutorialsHtml, doc.internal.pageSize.getWidth()), {
      callback: (pdf) => {
        pdf.save("睡吧评估结果及相关文章.pdf", {returnPromise:true}).then(callback())
      },
      margin: 18,
    })
  }))
}
