import React from "react";
import { parseAnswers } from "./services";

const AnswerTable = React.forwardRef((props, ref) => {
  const { sections, answer, answerId } = props;
  const answers = parseAnswers(answer, sections, answerId);

  return (
    <div className="content">
      <ol type="1" ref={ref}>
        {answers.map((answer, i) => (
          <li key={`answer_${i}`}>
            {answer.q} ：{answer.a}
          </li>
        ))}
      </ol>
    </div>
  );
});

export default AnswerTable;