import React from "react"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import EvaluateLogin from "../evaluation/login"
import ForgetPassword from '../evaluation/forget-password';
import ResetPassword from '../evaluation/reset-password';
import { Router } from "@reach/router"
import { PrivateRoute, PublicRoute } from "../evaluation/private-route"
import Evaluation from "../evaluation"
import EvaluateResult from "../evaluation/result"
import EvaluateHelp from "../evaluation/help"
import EvaluateDetails from '../evaluation/details'
import EvaluateConsult from "../evaluation/consult"
import { graphql } from "gatsby"

class EvaluatePage extends React.Component {
  render() {
    const booklets = this.props.data.allStrapiBooklet.edges.map(edge => edge.node)
    const instructioners = this.props.data.allStrapiInstructioner.edges.map(edge => edge.node)
    const sections = this.props.data.allStrapiSection.edges.map(edge => edge.node)

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet>
            <title>睡眠评估</title>
          </Helmet>
          <Router>
            <PrivateRoute path="/evaluate/new" component={Evaluation} sections={sections}></PrivateRoute>
            <PrivateRoute
              path="/evaluate/result"
              component={() =>
                <EvaluateResult booklets={booklets} instructioners={instructioners} />}>
            </PrivateRoute>
            <PrivateRoute path="/evaluate/help" component={EvaluateHelp} sections={sections}></PrivateRoute>
            <PrivateRoute path="/evaluate/details" component={EvaluateDetails} sections={sections}></PrivateRoute>
            <PrivateRoute path="/evaluate/consult" component={EvaluateConsult} sections={sections} />
            <PublicRoute
              path="/evaluate/results/:answerId"
              component={(props) =>
                <EvaluateResult answerId={props.answerId} booklets={booklets} instructioners={instructioners} />}>
            </PublicRoute>
            <PublicRoute
              path="/evaluate/details/:answerId"
              component={(props) =>
                <EvaluateDetails answerId={props.answerId} sections={sections} />}>
            </PublicRoute>
            <PublicRoute path="/evaluate/login" component={EvaluateLogin} />
            <PublicRoute path="/evaluate/forget-password" component={ForgetPassword} />
            <PublicRoute path="/evaluate/reset-password" component={ResetPassword} />
          </Router>
        </div>
      </Layout>
    )
  }
}

export default EvaluatePage

export const defaultResultQuery = graphql`
  query {
    allStrapiBooklet(limit: 1000) {
      edges {
        node {
          id: strapiId
          tag
          content {
            lang
            text
          }
        }
      }
    }
    allStrapiSection(limit: 1000) {
      edges {
        node {
          id: strapiId
          index
          description {
            zh
          }
          questions {
            name
            readonly
            options {
              placeHolder {
                zh
              }
              valueList {
                name
                description {
                  zh
                }
              }
              min
              max
              step
              defaultValue
              minDate
              maxDate
              unit
              unitDescripton {
                zh
              }
            }
            index
            type
            description {
              zh
            }
            depends {
              question
              value
            }
            hint {
              zh
            }
          }
        }
      }
    }
    allStrapiInstructioner(limit: 1000) {
      edges {
        node {
          id: strapiId
          type
          args
          answers {
            question
            value
          }
          fact {
            name
            booklet
            description {
              zh
            }
          }
        }
      }
    }
  }
`
