import React from "react"

let sliderId = 1

class Slider extends React.Component {
  constructor() {
    super()

    this.output = React.createRef()
    this.id = (sliderId ++)
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    this.props.onChange(e)
  }

  render() {
    const {name, value, options} = this.props;
    const v = value || options.defaultValue;

    return (
      <div className="control columns">
        <input
          aria-label={name}
          name={name}
          id={this.id}
          className="slider column is-10"
          min={options.min}
          max={options.max}
          step="1"
          value={v}
          onChange={e => this.onChange(e)}
          type="range" />
        <label className="column has-vertically-aligned-content" htmlFor={this.id} ref={this.output}>
          {`${v}小时`}
        </label>
      </div>
    )
  }
}

export default Slider
