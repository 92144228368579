import React, { useState, useEffect } from "react"
import Helmet from "react-helmet"
import { Link } from "gatsby";
import { FaArrowLeft } from "react-icons/fa"
import AnswerTable from "./answer-table"
import { fetchAnswersWithId, fetchAnswers } from "./services"

const EvaluateDetails = (props) => {
  const { sections } = props;
  const [answerId, setAnswerId] = useState(props.answerId);
  const [answers, setAnswers] = useState(null);

  async function fetchData() {
    if (!answers) {
      const result = answerId ? await fetchAnswersWithId(answerId) : await fetchAnswers();

      if (!result) return;

      setAnswerId(result.id);

      setAnswers(result.results);
    }
  }

  useEffect(() => {
    fetchData();
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps

  const toUrl = answerId ? `/evaluate/results/${answerId}` : '/evaluate/result';

  const renderNav = () => {
    return (
      <div className="control">
        <Link className="button is-light" to={toUrl}>
          <FaArrowLeft style={{ verticalAlign: `middle`, marginRight: 5 }} />
          返回评估结果和意见
        </Link>
      </div>
    )
  }

  if (!answers) return null;

  return (
    <div>
      <Helmet>
        <title>评估详情</title>
      </Helmet>
      <h1 className="title is-spaced">评估详情</h1>
      {renderNav()}
      <AnswerTable sections={sections} answer={answers} answerId={answerId}/>
    </div>
  );
};

export default EvaluateDetails;