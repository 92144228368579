import React from "react"
import DatePicker from "../components/date-picker"
import Slider from "../components/slider"
import { getLangText } from '../utils/common'
import * as classNames from 'classnames';

class Field extends React.Component {
  constructor() {
    super()

    this.onChange = this.onChange.bind(this)
    this.onSelect = this.onSelect.bind(this)
    this.elem = React.createRef()
  }

  renderField(input) {
    const { error, question: q, value } = this.props
    const type = q.type

    const inputClass = classNames('input', { 'is-danger': error });
    const selectClass = classNames('select', { 'is-danger': error });

    if (type === 'text' || type === 'email') {
      return (
        <div className="control">
          <input
            className={inputClass}
            {...input}
            placeholder={getLangText(q.options.placeHolder)}
            readOnly={q.readonly}
            type={type} />
        </div>
      );
    }

    if (type === 'textarea') {
      return (
        <div className="control">
          <textarea className="textarea" aria-label={q.placeHolder} placeholder={q.placeholder}></textarea>
        </div>
      )
    }

    if (type === 'date') {
      return (
        <DatePicker
          {...input}
          type={type}
          options={q.options}
          error={error} />
      );
    }

    if (type === 'range') {
      return (<Slider {...input} options={q.options} />);
    }

    if (type === 'multiple') {
      const { valueList } = q.options
      
      return (
        <div className="control radio-list">
          {valueList.map((item, i) =>
            <label className="radio" key={i}>
              <input
                {...input}
                name={q.name}
                value={item.name}
                type='radio'
                checked={value === item.name}
              />
              {getLangText(item.description)}
            </label>
          )}
        </div>
      );
    }

    if (type === 'select') {
      return (
        <div className="control">
          <div className={selectClass}>
            <select {...input} name={q.name} className={selectClass}>
              <option aria-label={q.name}></option>
              {q.data.map((item, i) =>
                <option value={item.value} key={i}>{item.text}</option>
              )}
            </select>
          </div>
        </div>
      );
    }
  }

  onChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name) {
      this.props.onChange({ [name]: value })
    }
  }

  onSelect(props) {
    const { name, value } = props

    this.props.onChange({ [name]: value })
  }

  render() {
    const { question, error, value } = this.props

    return (
      <div className="field">
        <label className="label">{getLangText(question.description)}</label>
        {this.renderField({
          value: value || '',
          onChange: this.onChange,
          onSelect: this.onSelect,
          name: question.name,
          ref: this.elem
        })}
        {error && <p className="help is-danger">{error}</p>}
      </div>
    )
  }
}

export default Field
