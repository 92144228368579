import React from "react"
import PropTypes from "prop-types"
import { getCachedUser } from "../utils/auth"
import { navigate } from "gatsby"
import ErrorBoundary from "./error-boundary"


const PrivateRoute = ({ component: Component, ...rest }) => {
  const { location } = rest;
  
  if (!getCachedUser() && location.pathname !== '/evaluate/login') {
    navigate('/evaluate/login')
  }

  return <Component {...rest} />
}

const PublicRoute = ({ component: Component, ...rest }) => (
  <ErrorBoundary>
    <Component {...rest} />
  </ErrorBoundary>
);

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export { PrivateRoute, PublicRoute }
