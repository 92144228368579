import React from "react"
import { navigate, Link } from "gatsby"
import { handleRegister, handleLogin, getCachedUser, isUserExsit } from "../utils/auth"
import { validateEmail, validatePassword } from "../utils/methods"
import { fetchAnswers } from "./services"

class EvaluateLogin extends React.Component {
  state = {
    email: '',
    password: ''
  }

  onEmailChange(e) {
    const email = e.target.value;

    this.setState({
      email,
      usernameError: validateEmail(email) ? null : new Error('请输入正确邮箱格式')
    });
  }

  onPasswordChange(e) {
    this.setState({ password: e.target.value })
  }

  isValidate() {
    return validateEmail(this.state.email) && validatePassword(this.state.password)
  }

  // this should handle both login and register
  handleSubmit(e) {
    e.preventDefault()

    isUserExsit(this.state)
      .then(isExist => {
        if (isExist) {
          return handleLogin(this.state)
            .then(() => navigate('/evaluate/result'));
        }

        return handleRegister(this.state)
          .then(() => navigate('/evaluate/new'));
      })
      .catch(err => {
        this.setState({ error: err })
      });
  }

  async componentDidMount() {
    try {
      const answers = await fetchAnswers()

      if (!answers) return;

      this.setState({ answers: answers.results || {} })
    } catch(e) {
      this.setState(() => {
        throw e;
      });
    }
  }

  render() {
    const { usernameError, error, answers } = this.state

    if (getCachedUser()) {
      if (!answers) return (<div>请稍等……</div>);

      if (answers && Object.keys(answers) !== 0) {
        navigate(`/evaluate/result`)
      } else {
        navigate(`/evaluate/new`)
      }
    }

    // login UI is coming from this code: https://codepen.io/Ridrog/pen/wgqorw
    return (
      <form
        className="field"
        onSubmit={e => this.handleSubmit(e)}
      >
        <section className="hero is-bold">
          <div className="hero-body">
            <div className="container">
              <div className="columns is-vcentered">
                <div className="column is-6 is-offset-3">
                  <h1 className="title is-spaced">睡眠评估</h1>
                  <p className="m-b-md">评估之前，阅读<Link to="/help">求助指南</Link>了解这样做的原因。</p>
                  <div className="box">
                    <label className="label" htmlFor="username">邮箱</label>
                    <p className="control has-icon has-icon-right m-b-sm">
                      <input className="input" aria-label="邮箱" name="username" type="email" placeholder="username@email.example.com" onChange={e => this.onEmailChange(e)} />
                      {usernameError ? (<p className="help is-danger">{usernameError.message}</p>) : null}
                    </p>
                    <label className="label" htmlFor="password">密码</label>
                    <p className="control has-icon has-icon-right">
                      <input className="input" aria-label="密码" name="password" type="password" placeholder="●●●●●●●" onChange={e => this.onPasswordChange(e)} />
                    </p>
                    <p className="control m-t-md">
                      <button className="button is-primary" type="submit" disabled={!this.isValidate()}>进入评估</button>
                      <span className="m-l-md is-size-6 has-text-info">(首次使用系统会自动创建用户)</span>
                    </p>
                    {error ? (
                      <p className="help is-danger">服务器错误，请<Link href="mailto:hello.sleep.site@gmail.com">联系管理员</Link>：{error.message}</p>
                    ) : null}
                  </div>
                  <p className="has-text-centered">
                    <Link to="/evaluate/forget-password">忘记密码</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>

    )
  }
}

export default EvaluateLogin
