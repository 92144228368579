import React from "react"
import Question from './question'

export default function EvaluationForm(props) {
  const { questions, answers, onChange } = props;

  const getValue = (q) => {
    const v = answers[q.name];

    return typeof v === 'object' ? JSON.parse(JSON.stringify(v)) : v;
  }

  return (
    <form>
      {
        questions.map((q, i) =>
          <Question
            key={i}
            question={q}
            value={getValue(q)}
            handleChanage={onChange}
          />)
      }
    </form>
  )
}
